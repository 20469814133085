import { settingObj } from './SettingSlice';

export const isProduction = process.env.NODE_ENV === 'production';

export function isEmpty(str) {
    if (str === null || str === undefined || !str || str === "" || 'undefined' === str || 'null' === str) {
        return true;
    }
    return false;
}

export function isEmail(str) {
    if (isEmpty(str) || str.length < 1 || str.length > 255) {
        return false;
    }
    return str.match('[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}');
}

export function isPassword(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)[0-9A-Za-z_]{8,30}$');
}

export function isVerifyCode(str) {
    if (isEmpty(str)) {
        return false;
    }
    return str.match('[0-9]{6}');
}


export function saveLocalString(key, value) {
    if (typeof window !== 'undefined') {
        if (value == null) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
    }
}

export function saveSessionString(key, value) {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(key, value);
    }
}

export function getLocalString(key) {
    if (typeof window !== 'undefined') {
        const str = localStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function getSessionString(key) {
    if (typeof window !== 'undefined') {
        const str = sessionStorage.getItem(key);
        if (isEmpty(str)) {
            return "";
        }
        return str;
    } else {
        return "";
    }
}

export function removeLocalString(key) {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(key);
    }
}

export function clearLocalString() {
    if (typeof window !== 'undefined') {
        localStorage.clear();
    }
}

let os = function () {
    let ua = navigator.userAgent,
        isWindowsPhone = /(?:Windows Phone)/.test(ua),
        isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
        isAndroid = /(?:Android)/.test(ua),
        isFireFox = /(?:Firefox)/.test(ua),
        isChrome = /(?:Chrome|CriOS)/.test(ua),
        isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
        isPhone = /(?:iPhone)/.test(ua) && !isTablet,
        isPc = !isPhone && !isAndroid && !isSymbian;
    return {
        isTablet: isTablet,
        isPhone: isPhone,
        isAndroid: isAndroid,
        isPc: isPc
    };
}();

export let isMobile = function isMobile() {
    if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true;
    }
    return false;
}();

export let isAndroid = function () {
    if (os.isAndroid || (navigator.userAgent.toLowerCase().indexOf("android") !== -1)) {
        return true;
    }
    return false;
}();

export let isIOS = function () {
    let str = navigator.userAgent.toLowerCase();
    if (os.isPhone || (str.indexOf("iphone") !== -1) || str.indexOf("ipad") !== -1) {
        return true;
    }
    return false;
}();

export function uuid(num) {
    let x = num;
    if (x == null || x == undefined || x < 1) {
        x = 32;
    }
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < x) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}


export function getImgPath(name) {
    if (isEmpty(name)) {
        return '';
    }
    if (name.indexOf('game-cdn.aplusjapan-game.com') != -1) {
        return name;
    }
    if (name.indexOf('http') != -1) {
        return name;
    }

    return 'https://game-cdn.aplusjapan-game.com/sfd_web/images/' + name;
}

export function googleTap() {
    let url = 'https://play.google.com/store/apps/details?id=com.sfdapj.sea';
    const l = settingObj.region;
    const ad = settingObj.adPlatform;

    if (l == 'jp') {
        url = 'https://play.google.com/store/apps/details?id=com.sfdapj.ja';
        if (!isEmpty(ad)) {
            window.gtag_report_conversion('kiJiCNLM5ZgZEIuP8KMq');//广告
        }

    } else if (l == 'kr') {
        url = 'https://play.google.com/store/apps/details?id=com.sfdapj.kr';
        if (!isEmpty(ad)) {
            window.gtag_report_conversion('aYAjCNPZ7ZgZEIuP8KMq');//广告
        }

    } else {
        if (!isEmpty(ad)) {
            window.gtag_report_conversion('aE7RCKa14JgZEIuP8KMq');//广告
        }
    }

    window.fbq('track', 'sfd_download_tap_google_' + l + '_' + ad); //FB埋点
    window.gtag("event", 'sfd_download_tap_google_' + l); //GG埋点
    window.ttEvent('ClickButton', 'google'); //TT埋点

    window.open(url);
}

export function appleTap() {
    let url = '';
    const l = settingObj.region;
    const ad = settingObj.adPlatform;
    if (l == 'jp') { //日本
        url = 'https://apps.apple.com/jp/app/id6449665588';
        if (ad == 'FBpreg') {
            url = 'https://apps.apple.com/jp/app/ストリートファイター-デュエル/id6449665588?ppid=b70127da-940b-4f05-90ee-24ac44574ef5';

        } else if (ad == 'GGpreg') {
            url = 'https://apps.apple.com/jp/app/ストリートファイター-デュエル/id6449665588?ppid=fe4ae55a-8dc7-4340-8a9b-dbd42902ec77';

        } else if (ad == 'TTpreg') {
            url = 'https://apps.apple.com/us/app/ストリートファイター-デュエル/id6449665588?ppid=4a9ec3bc-ed53-4a57-abce-513d1495230c';

        }

        if (!isEmpty(ad)) {
            window.gtag_report_conversion('3NdnCM_M5ZgZEIuP8KMq');//广告
        }

    } else if (l == 'kr') {//韩国
        url = 'https://apps.apple.com/kr/app/id6474011274';
        if (ad == 'FBpreg') {
            url = 'https://apps.apple.com/kr/app/스트리트-파이터-듀얼/id6474011274?ppid=8a15a230-2563-4c5f-8173-c78a49a588fd';

        } else if (ad == 'GGpreg') {
            url = 'https://apps.apple.com/kr/app/스트리트-파이터-듀얼/id6474011274?ppid=c744d09f-89ad-41bc-a96a-cd0e48160927';

        } else if (ad == 'TTpreg') {
            url = 'https://apps.apple.com/us/app/스트리트-파이터-듀얼/id6474011274?ppid=630f8416-9274-4b09-a8eb-41d35842c8c5';

        } else if (ad == 'FBob') {
            url = 'https://apps.apple.com/kr/app/스트리트-파이터-듀얼/id6474011274?ppid=42de8024-532a-4007-bcd2-74d5de097c4b';

        } else if (ad == 'TTob') {
            url = 'https://apps.apple.com/kr/app/스트리트-파이터-듀얼/id6474011274?ppid=c008b566-02e3-44d8-87a1-4eb71d6061cb';

        }

        if (!isEmpty(ad)) {
            window.gtag_report_conversion('BINDCNDZ7ZgZEIuP8KMq');//广告
        }

    } else { //东南亚
        url = 'https://apps.apple.com/sg/app/id6474011189';
        if (ad == 'FBpreg') {
            url = 'https://apps.apple.com/sg/app/street-fighter-duel/id6474011189?ppid=5be63b04-0e98-4ecc-8c30-294f49c8b7c4';

        } else if (ad == 'GGpreg') {
            url = 'https://apps.apple.com/sg/app/street-fighter-duel/id6474011189?ppid=5c21a32a-8ad0-41f1-9a5a-3ce92bceecf3';

        } else if (ad == 'TTpreg') {
            url = 'https://apps.apple.com/us/app/street-fighter-duel/id6474011189?ppid=9da97849-ebff-4118-9961-b5721ae15b8b';

        }

        if (!isEmpty(ad)) {
            window.gtag_report_conversion('n89PCKO14JgZEIuP8KMq'); //广告
        }
    }

    window.fbq('track', 'sfd_download_tap_apple_' + l + '_' + ad); //FB埋点
    window.gtag("event", 'sfd_download_tap_apple_' + l); //GG埋点
    window.ttEvent('ClickButton', 'apple'); //TT埋点
    window.open(url);
}

export function preRegisterTap() {
    let url = '';
    const l = settingObj.region;
    const ad = settingObj.adPlatform;
    if (l == 'jp') {
        url = 'https://sfdjp.onelink.me/Gg8K/wb0x9uzo';

    } else if (l == 'kr') {
        url = 'https://sfdkr.onelink.me/lUTJ/wb2is2p0';

    } else {
        url = 'https://sfdsea.onelink.me/ai8U/q6zvh921';
    }

    window.fbq('track', 'sfd_download_tap_onestore_' + l + '_' + ad); //FB埋点
    window.gtag("event", 'sfd_download_tap_pre_register' + l); //GG埋点
    window.ttEvent('ClickButton', 'pre_register'); //TT埋点
    window.open(url);
}

//下载
export function onestoreTap() {
    const l = settingObj.region;
    const ad = settingObj.adPlatform;
    if (!isEmpty(ad)) {
        window.gtag_report_conversion('YxndCNbZ7ZgZEIuP8KMq'); //广告
    }

    window.fbq('track', 'sfd_download_tap_onestore_' + l + '_' + ad); //FB埋点
    window.gtag("event", 'sfd_download_tap_onestore_' + l); //GG埋点
    window.ttEvent('ClickButton', 'onestore'); //TT埋点
    window.open('https://onesto.re/0000774386');
}

// 分享
export function shareFBTap() {
    window.open('https://www.facebook.com/profile.php?id=100093416833230');
}

export function shareTTTap() {
    let url = '';
    const l = settingObj.region;
    if (l == 'jp') {
        url = 'https://twitter.com/SFD_jp';
        window.open(url);
    } else if (l == 'kr') {
        url = 'https://twitter.com/SFD_kr';
        window.open(url);
    }
}

export function shareOneTap() {
    window.open('https://game.naver.com/lounge/Street_Fighter_Duel/home');
}

export function getAPIHost() {
    const x = getRegion();
    if (x == 'jp') {
        return 'https://jp-admin.sfduelmobile.com/console/api';
    } else if (x == 'kr') {
        return 'https://kr-admin.sfduelmobile.com/console/api';
    }
    return 'https://sea-admin.sfduelmobile.com/console/api';
}

export function getAPILang() {
    let l = getLang();
    if (l == 'jp') {
        return 'ja';
    } else if (l == 'kr') {
        return 'ko';
    } else if (l == 'cn') {
        return 'zh-hans';
    } else if (l == 'tw') {
        return 'zh-hant';
    }
    return 'en';
}

export function getLang() {
    const x = window.location.origin;
    if (x.indexOf('jp.sfduelmobile') != -1) {
        return 'jp';
    } else if (x.indexOf('kr.sfduelmobile') != -1) {
        return 'kr';
    }
    const localLan = getLocalString('local_lang');
    if ('cn' == localLan || 'tw' == localLan) {
        return localLan;
    }
    return 'en';
}

export function getRegion() {
    const x = window.location.origin;
    if (x.indexOf('jp.sfduelmobile') != -1) {
        document.title = 'ストリートファイター: デュエル_ストD';
        return 'jp';
    } else if (x.indexOf('kr.sfduelmobile') != -1) {
        document.title = '스트리트 파이터: 듀얼';
        return 'kr';
    }
    return 'en'; //东南亚是en
}